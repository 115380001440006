import type { Environment } from "relay-runtime";

let relayEnvironment: Environment | null = null;

export const setRelayEnvironment = (environment: Environment) => {
  relayEnvironment = environment;
};

export const getRelayEnvironment = () => {
  if (!relayEnvironment) {
    throw new Error("Relay environment not set");
  }
  return relayEnvironment;
};
